<template>
  <div>
    <a-modal title="一键设置"
             v-model:visible="dialogVisible"
             @cancel="handleClose"
             :maskClosable="true"
             @ok="save"
             :close-on-click-modal="false">
      <a-form :model="form">
        <a-row :gutter="10">
          <a-col :span="22">
            <a-form-item label="年级">
              <a-select style="width:100%"
                        v-model:value="form.grade_id"
                        @change="gradeChange"
                        placeholder="请选择年级">
                <a-select-option v-for="item in gradeList"
                                 :key="item.grade_id"
                                 :value="item.grade_id">
                  {{item.grade_name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="22">
            <a-form-item label="模式">
              <a-select style="width:100%"
                        v-model:value="form.mode_id"
                        placeholder="请选择模式">
                <a-select-option v-for="item in modeList"
                                 :key="item.id"
                                 :value="item.id">
                  {{item.mode_name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref, toRefs } from "@vue/reactivity";
import { getAllSubject, batchResetUserGradeSubject } from '@/api/user.js'
import { message } from "ant-design-vue";
const emit = defineEmits(['getAllList'])
const props = defineProps({
  modeList: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

let { modeList: pModeList } = toRefs(props)

let dialogVisible = ref(false)
let form = reactive({})
let gradeList = ref([])
let modeList = ref([])

const handleClose = () => {
  dialogVisible.value = false
}
const getGradeList = async () => {
  const { data } = await getAllSubject()
  gradeList.value = data.list
}

const save = async () => {
  if (!form.grade_id) {
    message.warning('请选择年级')
    return
  }

  if (!form.mode_id) {
    message.warning('请选择模式')
    return
  }
  let data = {
    edu_grade_id: form.grade_id,
    mode_id: form.mode_id
  }
  await batchResetUserGradeSubject(data)
  message.success('操作成功')
  handleClose()
  emit('getAllList')
}

const gradeChange = () => {
  modeList.value = []
  form.mode_id = undefined
  if (form.grade_id == '') return
  modeList.value = pModeList.value[form.grade_id]
}

defineExpose({
  dialogVisible,
  form,
  getGradeList
})
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 0;
}
.btn_warp {
  height: 50px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  margin-top: 10px;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #999999;
      border-right: 1px solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__header {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog__title {
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
::v-deep .el-dialog {
  border-radius: 10px;
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
}

::v-deep .a-form-item__label {
  font-weight: bold;
}
</style>