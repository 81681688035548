<template>
  <div class="my_info_warp">
    <a-form :model="form"
            class="form_Warp">
      <a-row v-for="(item,index) in allList"
             :key="index">
        <a-form-item :label="item.subject_name + ':'">
          <div class="shade"
               v-if=" form.edu_school_id!=1"></div>
          <a-select v-model:value="item.grade_id"
                    placeholder="请选择"
                    @change="gradeChange(item,index)"
                    style="width:50%">
            <a-select-option v-for="items in gradeList[item.subject_id]"
                             :key="items.grade_id"
                             :value="items.grade_id">
              {{items.grade_name}}
            </a-select-option>
          </a-select>
          <a-select v-model:value="item.mode_id"
                    placeholder="请选择"
                    style="width:49%;margin-left:1%">
            <!-- <a-select-option v-for="mode in modeList[item.grade_id]"
                             :key="mode.id"
                             :disabled="!getStatus(item,mode)"
                             :value="mode.id">
              <span v-show="getStatus(item,mode)">{{mode.mode_name}}</span>
            </a-select-option> -->
            <a-select-option v-for="mode in filterMode(item)"
                             :key="mode.id"
                             :value="mode.id">
              {{mode.mode_name}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-row>
    </a-form>
    <div class="btn_warp"
         v-if="form.edu_school_id && allList.length">
      <div class="btn"
           @click="save">
        确 定
      </div>
      <div class="btn"
           @click="reset">
        一键设置
      </div>
    </div>

    <ResetDialog ref="resetDialogRef"
                 :modeList="modeList"
                 @getAllList="getAllList" />

  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { computed } from 'vue'
import { getUserInfoApi, getStuSubjectList, getSubjectGrade, getUndefinedSubject, getSubjectMode, editStuSubject } from '@/api/user.js'
import { message } from "ant-design-vue";
import ResetDialog from './resetDialog.vue'


let form = reactive({})
let gradeList = ref([])
let modeList = ref([])
let allList = ref([])
let list = reactive({})
const resetDialogRef = ref(null)


onMounted(() => {
  getInfo()
})

// 获取用户信息
const getInfo = async () => {
  const { data } = await getUserInfoApi()
  for (const key in data) {
    form[key] = data[key]
  }
  // console.log(form)
  getAllList()
}

const getAllList = async () => {
  const { data } = await getStuSubjectList()
  allList.value = data.list
  getGradeList()
  if (form.edu_school_id == 1) {
    getUndistrbuted()
  }
}

// 获取各科目对应的年级
const getGradeList = async () => {
  const { data } = await getSubjectGrade()
  gradeList.value = data
  getModeList()
}

// 获取学生未分配的科目
const getUndistrbuted = async () => {
  const { data } = await getUndefinedSubject()
  data.list.forEach(item => {
    item.grade_id = ''
    item.mode_id = ''
    allList.value.push(item)
  });
}

const getModeList = async () => {
  const { data } = await getSubjectMode()
  modeList.value = data.list
}

const gradeChange = (row, index) => {
  // 生物地理科目id   初二的时候 没有学习模式
  let SD = [9, 10]
  row.mode_id = ''
  if (row.grade_id == 3) {
    row.mode_id = 5
  }
  if (row.grade_id == 2) { //&& row.subject_id != 10 && row.subject_id != 9
    row.mode_id = 3
  }
  if (row.grade_id == 1) {
    row.mode_id = 1
  }
}

let filterMode = computed(() => (row) => {
  if ((row.subject_id == 9 || row.subject_id == 10) && row.grade_id == 2) {
    return modeList.value[row.grade_id]
  } else if (row.grade_id == 2) {
    if (modeList.value[row.grade_id]) {
      return modeList.value[row.grade_id].filter(i => i.mode_name != '备考模式')
    }
  } else {
    return modeList.value[row.grade_id]
  }
})

// const getStatus = (row, mode) => {
//   if ((row.subject_id == 9 || row.subject_id == 10) && row.grade_id == 2) {
//     return true
//   }
//   if (row.grade_id == 2 && mode.mode_name == '备考模式') {
//     return false
//   }
//   return true
// }

const reset = () => {
  let form = {
    grade_id: '',
    mode_id: ''
  }
  resetDialogRef.value.form = form
  resetDialogRef.value.getGradeList()
  resetDialogRef.value.dialogVisible = true
}

const save = async () => {
  let arr = allList.value.map(item => {
    return {
      grade_id: item.grade_id,
      mode_id: item.mode_id,
      subject_id: item.subject_id
    }
  })
  await editStuSubject({ subject_grade: JSON.stringify(arr) })
  message.success("修改成功")
  getInfo()

}
</script>

<style lang="scss" scoped>
.my_info_warp {
  position: relative;
  transform: translateX(-10px);
  .head {
    margin-top: 35px;
    text-align: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  .form_Warp {
    margin: 0 auto;
    margin-top: 30rem;
    max-height: calc(100vh - 265rem);
    overflow: auto;
  }
}
.btn_warp {
  display: flex;
  font-size: 24rem;
  justify-content: center;
  margin-left: 70rem;
  margin-top: 38rem;
  .btn {
    width: 350rem;
    height: 60rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #2196f3;
    border-radius: 10rem;
    &:nth-child(2) {
      margin-left: 1%;
      background: #cbe8ff !important;
      font-weight: bold;
      color: #2196f3;
    }
  }
}

:deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 350rem;
  height: 60rem;
  font-size: 24rem;
  display: flex;
  align-items: center;
}
:deep .ant-form-item-label > label {
  font-size: 24rem;
  height: 60rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333333;
}
:deep .ant-form-item {
  margin: 30rem auto 0;
}
.ant-row {
  position: relative;
  .shade {
    position: absolute;
    width: 100%;
    height: 60rem;
    z-index: 1999;
  }
}
</style>